import Swiper from "swiper/bundle";
import SimpleBar from "simplebar";

$(document).ready(function() {
  $(".hamburger").click(function() {
    $(this).toggleClass("active");
    $(".nav").toggleClass("active");
    $("body").toggleClass("hidden");
  });

  var scr = $(".services__wrapper");
  scr.mousedown(function() {
    var startX = this.scrollLeft + event.pageX;

    scr.mousemove(function() {
      this.scrollLeft = startX - event.pageX;

      return false;
    });
  });

  $(window).mouseup(function() {
    scr.off("mousemove");
  });

  const actualBtn = document.getElementById('actual-btn');
  const fileChosen = document.getElementById('file-chosen');
  if (actualBtn && fileChosen) {
    actualBtn.addEventListener('change', function () {
      fileChosen.textContent = this.files[0].name
    })
  }
});